/*-----------------------------------------------------------------------------------
    less/responsive.less
-----------------------------------------------------------------------------------*/


// Initialize
@import "variables.less";
@import "mixins.less";


@media only screen and (max-width: 1199px) {

	canvas {
		max-width: 100%;
		height: auto !important;
	}

	.promo {
		h3 {
			font-size: 20px;
		}
	}

	.promo > span,
	.promo > .container > span {
		font-size: 15px;
	}

	.promo-uppercase {
		h3 {
			font-size: 19px;
		}
	}

	.promo-uppercase > span,
	.promo-uppercase > .container > span {
		font-size: 14px;
	}

}


@media only screen and (min-width: 992px) and (max-width: 1199px) {

	#wrapper {
    	width: 1000px;
	}

	#header {
	    &.transparent-header {
	        &.floating-header {
	            .container {
	                width: 1030px;
	                padding: 0 30px;
	            }
	            &.sticky-header {
	                .container {
	                    width: 970px;
	                    padding: 0 15px;
	                }
	            }
	        }
	    }
	}

	#primary-menu {
	    &.sub-title {
	        ul {
	            > li {
	                > a {
	                    padding-left: 15px;
	                    padding-right: 15px;
	                }
	            }
	        }
	    }
	}

	#primary-menu ul li.mega-menu .mega-menu-content,
		.floating-header.sticky-header #primary-menu ul li.mega-menu .mega-menu-content {
	    width: 940px;
	}

	.floating-header {
	    #primary-menu {
	        ul {
	            li {
	                &.mega-menu {
	                    .mega-menu-content {
	                        width: 1030px;
	                    }
	                }
	            }
	        }
	    }
	}
	#primary-menu ul li a {
		letter-spacing: 1px;
		padding: 0 10px;
		font-size:12px;
	}
	.ei-slider {
    	height: 450px;
	}

	.postcontent {
	    width: @postcontent-md;
	    &.bothsidebar {
	        width: @bothsidebar-md;
	    }
	}

	.sidebar {
	    width: @sidebar-md;
	    .widget {
	        iframe {
	            width: @sidebar-md !important;
	        }
	    }
	}

	.team {
	    &.team-list {
	        .team-image {
	            width: 200px;
	        }
	    }
	}

	.testi-image,
	.testi-image a,
	.testi-image img,
	.testi-image i {
	    display: block;
	    width: 36px;
	    height: 36px;
	}

	.testi-image {
	    i {
	        line-height: 36px;
	        font-size: 20px;
	    }
	}

	/* ----------------------------------------------------------------
    Portfolio
	-----------------------------------------------------------------*/

	.portfolio-item {
		.portfolio-item-width(940px; 4; @portfolio-4-margin);
	}

	.portfolio-item .portfolio-image,
	.portfolio-item .portfolio-image a,
	.portfolio-item .portfolio-image img {
		.portfolio-item-size(940px; 4; @portfolio-4-margin);
	}

	/* Portfolio - No Margin
	-----------------------------------------------------------------*/

	.portfolio-nomargin {
		.portfolio-item {
			.portfolio-item-width(940px; 4; 0);
		}
	}

	.portfolio-nomargin .portfolio-item .portfolio-image,
	.portfolio-nomargin .portfolio-item .portfolio-image a,
	.portfolio-nomargin .portfolio-item .portfolio-image img {
		.portfolio-item-size(940px; 4; 0);
	}

	/* Portfolio - Sidebar
	-----------------------------------------------------------------*/

	.postcontent {
		.portfolio-item {
			.portfolio-item-width(@postcontent-md; 4; @portfolio-4-margin-sb);

			.portfolio-item .portfolio-image,
			.portfolio-item .portfolio-image a,
			.portfolio-item .portfolio-image img {
				.portfolio-item-size(@postcontent-md; 4; @portfolio-4-margin-sb);
			}
		}

		.portfolio-3 {
			.portfolio-item {
				.portfolio-item-width(@postcontent-md; 3; @portfolio-3-margin-sb);
			}
			&.portfolio-nomargin {
				.portfolio-item {
					.portfolio-item-width(@postcontent-md; 3; 0);
				}
			}
		}

		.portfolio-2 {
			.portfolio-item {
				.portfolio-item-size(@postcontent-md; 2; @portfolio-2-margin-sb);
			}
			&.portfolio-nomargin {
				.portfolio-item {
					.portfolio-item-size(@postcontent-md; 2; 0);
				}
			}
		}

		.portfolio-1 {
			.portfolio-desc {
				width: 250px;
				padding: 0;
			}
		}
	}


	.postcontent .portfolio-nomargin .portfolio-item,
	.postcontent .portfolio-5.portfolio-nomargin .portfolio-item {
		.portfolio-item-width(@postcontent-md; 4; 0);
	}

	.postcontent .portfolio-nomargin .portfolio-item .portfolio-image,
	.postcontent .portfolio-nomargin .portfolio-item .portfolio-image a,
	.postcontent .portfolio-nomargin .portfolio-item .portfolio-image img,
	.postcontent .portfolio-5.portfolio-nomargin .portfolio-item .portfolio-image,
	.postcontent .portfolio-5.portfolio-nomargin .portfolio-item .portfolio-image a,
	.postcontent .portfolio-5.portfolio-nomargin .portfolio-item .portfolio-image img {
		.portfolio-item-size(@postcontent-md; 4; 0);
	}

	/* Portfolio - Both Sidebar
	-----------------------------------------------------------------*/

	.bothsidebar {
		.portfolio-item {
			.portfolio-item-width(@bothsidebar-md; 4; @portfolio-4-margin-bs);
			.portfolio-image,
			.portfolio-image a,
			.portfolio-image img {
				.portfolio-item-size(@bothsidebar-md; 4; @portfolio-4-margin-bs);
			}
		}
		#portfolio {
			&.portfolio-3 {
				margin: 0 -@portfolio-3-margin-bs -@portfolio-3-margin-bs 0;
			}
		}
		.portfolio-1 {
			.portfolio-item {
				width: 100% !important;
				.portfolio-image {
					margin-bottom: 20px !important;
				}
			}
			.portfolio-desc {
				width: 100%;
				padding: 0;
			}
		}
		.entry-title {
			h2 {
				font-size: 19px;
				font-weight: 600;
			}
		}
		.entry-meta {
			li {
				margin-right: 10px;
			}
		}
	}

	.portfolio-3 {
		.portfolio-item {
			.portfolio-item-width(940px; 3; @portfolio-3-margin);

			.portfolio-image,
			.portfolio-image a,
			.portfolio-image img {
				.portfolio-item-size(940px; 3; @portfolio-3-margin);
			}
		}
		&.portfolio-nomargin {
			.portfolio-item {
				.portfolio-item-width(940px; 3; 0);

				.portfolio-image,
				.portfolio-image a,
				.portfolio-image img {
					.portfolio-item-size(940px; 3; 0);
				}
			}
		}
	}


	.postcontent .portfolio-3 .portfolio-item .portfolio-image,
	.postcontent .portfolio-3 .portfolio-item .portfolio-image a,
	.postcontent .portfolio-3 .portfolio-item .portfolio-image img {
		.portfolio-item-size(@postcontent-md; 3; @portfolio-3-margin-sb);
	}

	.postcontent .portfolio-3.portfolio-nomargin .portfolio-item .portfolio-image,
	.postcontent .portfolio-3.portfolio-nomargin .portfolio-item .portfolio-image a,
	.postcontent .portfolio-3.portfolio-nomargin .portfolio-item .portfolio-image img {
		.portfolio-item-size(@postcontent-md; 3; 0);
	}

	.portfolio-2 {
		.portfolio-item {
			.portfolio-item-size(940px; 2; @portfolio-2-margin);

			.portfolio-image,
			.portfolio-image a,
			.portfolio-image img {
				.portfolio-item-size(940px; 2; @portfolio-2-margin);
			}
		}
		&.portfolio-nomargin {
			.portfolio-item {
				.portfolio-item-size(940px; 2; 0);

				.portfolio-image,
				.portfolio-image a,
				.portfolio-image img {
					.portfolio-item-size(940px; 2; 0);
				}
			}
		}
	}

	.postcontent .portfolio-2 .portfolio-item .portfolio-image,
	.postcontent .portfolio-2 .portfolio-item .portfolio-image a,
	.postcontent .portfolio-2 .portfolio-item .portfolio-image img {
		.portfolio-item-size(@postcontent-md; 2; @portfolio-2-margin-sb);
	}

	.postcontent .portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image,
	.postcontent .portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image a,
	.postcontent .portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image img {
		.portfolio-item-size(@postcontent-md; 2; 0);
	}

	.bothsidebar .portfolio-2 .portfolio-item,
	.bothsidebar .portfolio-3 .portfolio-item {
		.portfolio-item-width(@bothsidebar-md; 2; @portfolio-2-margin-sb);
		margin: 0 @portfolio-2-margin-sb @portfolio-2-margin-sb 0;
	}

	.bothsidebar .portfolio-2 .portfolio-item .portfolio-image,
	.bothsidebar .portfolio-2 .portfolio-item .portfolio-image a,
	.bothsidebar .portfolio-2 .portfolio-item .portfolio-image img,
	.bothsidebar .portfolio-3 .portfolio-item .portfolio-image,
	.bothsidebar .portfolio-3 .portfolio-item .portfolio-image a,
	.bothsidebar .portfolio-3 .portfolio-item .portfolio-image img {
		.portfolio-item-size(@bothsidebar-md; 2; @portfolio-2-margin-bs);
	}

	.bothsidebar .portfolio-2.portfolio-nomargin .portfolio-item,
	.bothsidebar .portfolio-3.portfolio-nomargin .portfolio-item {
		.portfolio-item-width(@bothsidebar-md; 2; 0);
	}

	.bothsidebar .portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image,
	.bothsidebar .portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image a,
	.bothsidebar .portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image img,
	.bothsidebar .portfolio-3.portfolio-nomargin .portfolio-item .portfolio-image,
	.bothsidebar .portfolio-3.portfolio-nomargin .portfolio-item .portfolio-image a,
	.bothsidebar .portfolio-3.portfolio-nomargin .portfolio-item .portfolio-image img {
		.portfolio-item-size(@bothsidebar-md; 2; 0);
	}

	.portfolio-5 {
		.portfolio-item {
			.portfolio-item-width(940px; 5; @portfolio-5-margin);
		}

		&.portfolio-nomargin {
			.portfolio-item {
				.portfolio-item-width(940px; 5; 0);
			}
		}
	}

	.portfolio-5 .portfolio-item .portfolio-image,
	.portfolio-5 .portfolio-item .portfolio-image a,
	.portfolio-5 .portfolio-item .portfolio-image img {
		.portfolio-item-size(940px; 5; @portfolio-5-margin);
	}

	.portfolio-5.portfolio-nomargin .portfolio-item .portfolio-image,
	.portfolio-5.portfolio-nomargin .portfolio-item .portfolio-image a,
	.portfolio-5.portfolio-nomargin .portfolio-item .portfolio-image img {
		.portfolio-item-size(940px; 5; 0);
	}

	.portfolio-6 {
		.portfolio-item {
			.portfolio-item-width(940px; 6; @portfolio-6-margin);
		}
		&.portfolio-nomargin {
			.portfolio-item {
				.portfolio-item-width(940px; 6; 0);
			}
		}
	}

	.portfolio-6 .portfolio-item .portfolio-image,
	.portfolio-6 .portfolio-item .portfolio-image a,
	.portfolio-6 .portfolio-item .portfolio-image img {
		.portfolio-item-size(940px; 6; @portfolio-6-margin);
	}

	.portfolio-6.portfolio-nomargin .portfolio-item .portfolio-image,
	.portfolio-6.portfolio-nomargin .portfolio-item .portfolio-image a,
	.portfolio-6.portfolio-nomargin .portfolio-item .portfolio-image img {
		.portfolio-item-width(940px; 6; 0);
	}

	.portfolio-1 .portfolio-item .portfolio-image,
	.portfolio-1 .portfolio-item .portfolio-image a,
	.portfolio-1 .portfolio-item .portfolio-image img {
		width: 560px;
		height: 311px;
	}

	.portfolio-1 {
		.portfolio-desc {
			width: 340px;
		}
	}

	.bothsidebar #portfolio-shuffle,
	.postcontent .portfolio-1 .iconlist {
		display: none;
	}

	.postcontent .portfolio-1 .portfolio-item .portfolio-image,
	.postcontent .portfolio-1 .portfolio-item .portfolio-image a,
	.postcontent .portfolio-1 .portfolio-item .portfolio-image img {
		width: 400px;
		height: 222px;
	}

	.bothsidebar .portfolio-1 .portfolio-item .portfolio-image,
	.bothsidebar .portfolio-1 .portfolio-item .portfolio-desc {
		float: none;
		margin: 0 !important;
	}

	.bothsidebar .portfolio-1 .portfolio-item .portfolio-image,
	.bothsidebar .portfolio-1 .portfolio-item .portfolio-image a,
	.bothsidebar .portfolio-1 .portfolio-item .portfolio-image img {
		width: 100%;
		height: auto;
	}

	/* ----------------------------------------------------------------
    	Blog
	-----------------------------------------------------------------*/

	.postcontent {
		.small-thumbs {
			.entry-image {
				width: 200px;
			}
		}
		.post-grid {
			&.grid-3 {
				.entry {
					.blog-grid-item-width(@postcontent-md; 3; @blog-3-margin-sb);
				}
			}
			&.grid-2 {
				.entry {
					.blog-grid-item-width(@postcontent-md; 2; @blog-2-margin-sb);
				}
			}
		}
	}

	.bothsidebar {
		.small-thumbs {
			.entry-image {
				width: 120px;
			}
		}
		.post-grid {
			&.grid-2 {
				.entry {
					.blog-grid-item-width(@bothsidebar-md; 2; @blog-2-margin-bs);
				}
			}
		}
	}

	.post-grid {
		.entry {
			.blog-grid-item-width(940px; 4; @blog-4-margin);
		}
		&.grid-3 {
			.entry {
				.blog-grid-item-width(940px; 3; @blog-3-margin);
			}
		}
		&.grid-2 {
			.entry {
				.blog-grid-item-width(940px; 2; @blog-2-margin);
			}
		}
	}

	.post-grid .entry-image,
	.post-grid .entry-image img,
	.post-grid:not(.post-masonry) .entry-image iframe {
		.blog-grid-item-height(940px; 4; @blog-4-margin);
	}

	.post-grid.grid-3 .entry-image,
	.post-grid.grid-3 .entry-image img,
	.post-grid.grid-3:not(.post-masonry) .entry-image iframe {
		.blog-grid-item-height(940px; 3; @blog-3-margin);
	}

	.postcontent .post-grid.grid-3 .entry-image,
	.postcontent .post-grid.grid-3 .entry-image img,
	.postcontent .post-grid.grid-3:not(.post-masonry) .entry-image iframe {
		.blog-grid-item-height(@postcontent-md; 3; @blog-3-margin);
	}

	.post-grid.grid-2 .entry-image,
	.post-grid.grid-2 .entry-image img,
	.post-grid.grid-2:not(.post-masonry) .entry-image iframe {
		.blog-grid-item-height(940px; 2; @blog-2-margin);
	}

	.postcontent .post-grid.grid-2 .entry-image,
	.postcontent .post-grid.grid-2 .entry-image img,
	.postcontent .post-grid.grid-2:not(.post-masonry) .entry-image iframe {
		.blog-grid-item-height(@postcontent-md; 2; @blog-2-margin);
	}

	.bothsidebar .post-grid.grid-2 .entry-image,
	.bothsidebar .post-grid.grid-2 .entry-image img,
	.bothsidebar .post-grid.grid-2:not(.post-masonry) .entry-image iframe {
		.blog-grid-item-height(@bothsidebar-md; 2; @blog-2-margin);
	}

	.post-timeline {
		.entry {
			width: 400px !important;
			&.entry-date-section {
				width: 100% !important;
			}
		}
	}

	.mpost .entry-image,
	.mpost .entry-image a,
	.mpost .entry-image img,
	.mpost .entry-image i {
		width: 120px;
		height: 90px;
	}

	.mpost {
		.entry-image {
			a {
				i {
					line-height: 90px;
				}
			}
		}
	}

	.col_one_third {
		.landing-wide-form {
			padding: 36px 30px;
			.heading-block {
				h2 {
					font-size: 32px;
				}
				span {
					font-size: 17px;
				}
			}
		}
	}

	/* ----------------------------------------------------------------
    	Shop
	-----------------------------------------------------------------*/

	.product {
		.shop-item-width(940px; 4; @shop-4-margin);
	}

	.product-image {
		.shop-item-height(940px; 4; @shop-4-margin);
	}

	.product-3 {
		.product {
			.shop-item-width(940px; 3; @shop-3-margin);
		}
		.product-image {
			.shop-item-height(940px; 3; @shop-3-margin);
		}
	}

	.postcontent {
		.product-3 {
			.product {
				.shop-item-width(@postcontent-md; 3; @shop-3-margin-sb);
			}
			.product-image {
				.shop-item-height(@postcontent-md; 3; @shop-3-margin-sb);
			}
		}
		.product-2 {
			.product {
				.shop-item-width(@postcontent-md; 2; @shop-2-margin-sb);
			}
			.product-image {
				.shop-item-height(@postcontent-md; 2; @shop-2-margin-sb);
			}
		}
	}

	.bothsidebar {
		.product-2 {
			.product {
				.shop-item-width(@bothsidebar-md; 2; @shop-2-margin-bs);
			}
			.product-image {
				.shop-item-height(@bothsidebar-md; 2; @shop-2-margin-bs);
			}
		}
		.product-1 {
			.product-image {
				width: 200px;
				height: 267px;
			}
		}
	}

	.product-title {
		h3 {
			font-size: 18px;
		}
	}

	.panel {
		.portfolio-meta {
			li {
				padding-left: 22px;
				span {
					display: block;
					margin-left: -21px;
				}
			}
		}
	}

	.portfolio-single-image-full.portfolio-single-thumbs,
	.portfolio-single-image-full .swiper-container {
		height: 400px;
	}

	.bnews-slider {
		width: 817px;
	}

	.landing-form-overlay {
		bottom: -142px;
	}

}


@media only screen and (max-width: 991px) {

	#wrapper {
		width: 100%;
		margin: 0;
		box-shadow: none;
	}

	#header.full-header .container,
	.container-fullwidth {
		padding: 0 15px !important;
		margin: 0 auto;
		width: 750px !important;
	}

	.container {
		&.vertical-middle {
			width: 100% !important;
		}
	}
	.full-screen {
		&:not(.force-full-screen) {
			.container {
				&.vertical-middle {
					width: 750px !important;
				}
			}
		}
	}

	.vertical-middle {
		& + .video-wrap {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	.postcontent,
	.postcontent.bothsidebar,
	.sidebar {
		width: 100%;
		margin-right: 0;
		float: none !important;
		margin-bottom: 40px !important;
		padding-bottom: 40px;
		border-bottom: 1px solid #EEE;
	}

	.sidebar {
		&.col_last {
			float: none;
			margin-bottom: 0 !important;
			padding-bottom: 0;
			border-bottom: 0;
		}
	}

	/* ----------------------------------------------------------------
    	Header
	-----------------------------------------------------------------*/

	#header,
	#header-wrap,
	#header.sticky-style-2,
	#header.sticky-style-3,
	#header.sticky-style-2 #header-wrap,
	#header.sticky-style-3 #header-wrap {
		height: auto !important;
	}

	#header.sticky-style-2 #header-wrap,
	#header.sticky-style-3 #header-wrap {
		min-height: 60px;
	}

	#header {
		&.sticky-header {
			#header-wrap {
				position: relative;
			}
		}
		&.transparent-header {
			&.floating-header {
				margin-top: 0;
				.container {
					width: 750px;
					padding: 0 15px;
					.border-radius(0);
				}
			}
			& + #page-title {
				&.page-title-parallax {
					.container {
						padding-top: 0;
					}
				}
			}
		}
	}

	#header.transparent-header,
	#header.semi-transparent {
		background: #FFF;
		border-bottom: 1px solid #F5F5F5;
		z-index: auto;
	}

	#header.dark.transparent-header,
	#header.dark.semi-transparent {
		background-color: #333;
		border-bottom: 1px solid rgba(0,0,0,0.1);
	}

	#header.transparent-header + #slider,
	#header.transparent-header + #page-title.page-title-parallax,
	#slider + #header.transparent-header,
	#header.transparent-header.floating-header + #slider {
		top: 0;
		margin-bottom: 0;
	}

	#header-trigger,
	.top-advert,
	.header-extras {
		display: none;
	}

	#logo {
		display: block;
		height: 100px;
		float: none;
		margin: 0 auto 0 !important;
		max-width: none;
		text-align: center;
		border: 0 !important;
		padding: 0 !important;
		a {
			&.standard-logo {
				display: inline-block;
			}
		}
	}

	/* ----------------------------------------------------------------
    	Primary Menu
	-----------------------------------------------------------------*/

	#primary-menu {
			display: block;
			float: none;
			height: auto !important;
		.container {
			#primary-menu-trigger {
				top: 5px;
				left: 0;
			}
		}

		& > div {
			& > ul {
				margin-top: 60px !important;
			}
			& > #top-search {
				form {
					height: 60px !important;
				}
			}
		}

		ul {
			height: auto !important;
			li {
				float: none;
				margin: 0 !important;
				text-align: left !important;
				border-top: 1px solid #EEE;
				&:first-child {
					border-top: 0;
				}
				.mega-menu-content {
					padding-left: 0;
					ul {
						display: block;
						position: relative;
						top: 0;
						min-width: inherit;
						display: block !important;
						opacity: 1 !important;
						top: 0;
						left: 0;
						width: 100%;
						border: 0;
						box-shadow: none;
						background-color: transparent;
						ul {
							border-top: 1px solid #EEE;
						}
						a {
							height: 40px;
							line-height: 40px;
						}
					}
					& > ul {
						float: none;
						&:first-child {
							border: 0;
						}
					}

					&.style-2 {
						& > ul {
							padding: 0 0 0 15px;
							& > li {
								&.mega-menu-title {
									margin: 0;
									& > a {
										font-size: 13px;
										font-weight: 600;
										text-transform: none !important;
										margin: 0;
										padding: 0 0 0 5px;
										color: #444 !important;
										height: 44px !important;
										line-height: 44px !important;
										&:hover {
											color: @theme-color !important;
										}
									}
									&:hover {
										& > a {
											background-color: transparent;
											font-weight: 600;
											padding: 0 0 0 5px;
											color: @theme-color !important;
										}
									}
								}
							}
						}
						li {
							border-top: 1px solid #EEE;
							&:first-child {
								border-top: 0;
							}
						}
						ul {
							a {
								padding-left: 5px;
								height: 44px !important;
								line-height: 44px !important;
								&:hover {
									padding-left: 5px;
								}
							}
						}
					}
				}
				&.mega-menu {
					.mega-menu-content {
						&.style-2 {
							padding: 0;
						}
					}
				}
			}

			ul {
				ul {
					top: 0 !important;
					left: 0;
					&.menu-pos-invert {
						right: 0;
					}
				}
				li {
					float: none;
					margin: 0;
					border-top: 1px solid #EEE;
					&:hover {
						& > a {
							background-color: transparent !important;
							font-weight: 600;
							padding-left: 5px;
							color: @theme-color !important;
						}
					}
				}
				a {
					height: 44px !important;
					line-height: 44px !important;
					padding-left: 5px !important;
					font-size: 13px !important;
					text-transform: none;
					font-weight: 400;
					i {
						line-height: 43px;
					}
				}
				i {
					line-height: inherit;
				}
			}
		}

		&.style-5 {
			& > ul {
				padding-right: 0;
				margin-right: 0;
				border-right: 0;
				& > li {
					& > a {
						& > div {
							padding: 0;
							line-height: 50px;
						}
						i {
							display: inline-block;
							top: 1px;
							width: 14px;
							margin: 0 6px 0 0;
							font-size: 14px;
						}
					}
				}
			}
		}

		&.sub-title {
			ul {
				li {
					a {
						text-transform: none;
						font-size: 14px;
					}
					span {
						display: none !important;
					}
				}
			}
		}
	}

	#side-panel-trigger-close {
		a {
			display: block;
		    position: absolute;
		    z-index: 12;
		    top: 0;
		    left: auto;
		    right: 0;
		    width: 40px;
		    height: 40px;
		    font-size: 18px;
		    line-height: 40px;
		    color: #444;
		    text-align: center;
		    background-color: rgba(0,0,0,0.1);
		    .border-radius-custom(0, 0, 0, 2px);
		}
	}

	#primary-menu-trigger {
		opacity: 1;
		pointer-events: auto;
		top: 25px;
		margin-top: 0;
		left: 0;
		z-index: 1;
	}
	#primary-menu > ul,
	#primary-menu > div > ul {
		display: none;
		float: none !important;
		border: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}

	body {
		&:not(.top-search-open) {
			#primary-menu-trigger {
				opacity: 1;
				-webkit-transition: opacity .2s .2s ease, top .4s ease;
				-o-transition: opacity .2s .2s ease, top .4s ease;
				transition: opacity .2s .2s ease, top .4s ease;
			}
		}
		&.top-search-open {
			#primary-menu-trigger {
				opacity: 0;
			}
		}
	}

	#primary-menu ul li a,
	#primary-menu.style-2 > div > ul > li > a {
		height: 60px !important;
		line-height: 50px !important;
		padding: 5px !important;
		border: none !important;
		margin: 0 !important;
	}

	#primary-menu.sub-title > ul > li,
	#primary-menu.sub-title.style-2 > div > ul > li {
		background: none !important;
		margin-left: 0;
	}

	#primary-menu.sub-title > ul > li:hover > a,
	#primary-menu.sub-title > ul > li.current > a,
	#primary-menu.sub-title > div > ul > li:hover > a,
	#primary-menu.sub-title > div > ul > li.current > a,
	#primary-menu.style-3 > ul > li:hover > a,
	#primary-menu.style-3 > ul > li.current > a {
		background-color: transparent !important;
		color: #444;
		text-shadow: none;
	}

	#primary-menu ul li i,
	#primary-menu.style-2 > div > ul > li i,
	#primary-menu.sub-title ul li i,
	#primary-menu.style-3 > ul > li > a i,
	#primary-menu.style-5 > ul > li > a i {
		line-height: 49px;
	}

	#primary-menu.style-5 > ul > li > a i {
		display: inline-block;
		top: 1px;
		width: 14px;
		margin: 0 6px 0 0;
		font-size: 14px !important;
	}

	#primary-menu > ul > li.sub-menu > a,
	#primary-menu > .container > ul > li.sub-menu > a {
		background-image: url("../images/icons/submenu.png");
		background-position: right center;
		background-repeat: no-repeat;
	}

	#primary-menu ul ul,
	#primary-menu ul li .mega-menu-content {
		position: relative;
		width: auto;
		background-color: transparent !important;
		box-shadow: none;
		border: 0;
		border-top: 1px solid #EEE;
		z-index: 1;
		top: 0 !important;
		padding-left: 15px;
	}

	#primary-menu ul ul.menu-pos-invert,
	#primary-menu ul li .mega-menu-content.menu-pos-invert {
		left: 0;
		right: 0;
	}

	#primary-menu ul li .mega-menu-content,
	#primary-menu ul li.mega-menu-small .mega-menu-content,
	#primary-menu ul li.mega-menu .mega-menu-content,
	.floating-header.sticky-header #primary-menu ul li.mega-menu .mega-menu-content,
	.floating-header #primary-menu ul li.mega-menu .mega-menu-content {
		width: auto;
		margin: 0;
		top: 0;
	}

	.container-fullwidth #primary-menu ul li.mega-menu .mega-menu-content,
	#header.full-header #primary-menu ul li.mega-menu .mega-menu-content {
		width: auto !important;
		margin: 0 !important;
	}

	#primary-menu ul li .mega-menu-content.col-2 > ul,
	#primary-menu ul li .mega-menu-content.col-3 > ul,
	#primary-menu ul li .mega-menu-content.col-4 > ul,
	#primary-menu ul li .mega-menu-content.col-5 > ul {
		width: auto;
		margin: 0;
		border-left: 0;
		border-top: 1px solid #EEE;
	}

	#primary-menu {
		ul {
			li {
				.mega-menu-content { border: 0; }
			}
		}
	}

	/* Top Search
	-----------------------------------------------------------------*/

	#top-search {
		margin: 0 !important;
		form {
			height: 100px !important;
			input {
				font-size: 24px;
			}
		}
	}

	#top-search a,
	#top-cart,
	#side-panel-trigger {
		position: absolute;
		top: 0;
		left: auto;
		right: 15px;
		margin: 40px 0;
	}

	#top-search a { right: 43px; }

	#side-panel-trigger { right: 71px; }

	#primary-menu {
		> div {
			> #top-search a,
			> #top-cart,
			> #side-panel-trigger {
				margin: 20px 0 !important;
			}
		}
		ul.windows-mobile-menu {
			li.sub-menu { position: relative; }

			li.sub-menu a { background-image: none !important; }

			li.sub-menu a.wn-submenu-trigger {
				position: absolute;
				cursor: pointer;
				width: 32px;
				top: 0;
				right: 0;
				z-index: 1;
				padding: 0 !important;
				text-align: center !important;
			}

			.mega-menu-content a.wn-submenu-trigger { display: none; }

			li.sub-menu a.wn-submenu-trigger i { margin: 0 !important; }
		}
	}

	#header {
		&.sticky-style-3 {
			#top-cart {
				.top-cart-content {
					top: 40px;
				}
			}
		}
	}

	/* Page Menu
	-----------------------------------------------------------------*/

	#page-menu {
		.container {
			position: relative;
		}
		&.pagemenu-active {
			#page-submenu-trigger {
				background-color: rgba(0,0,0,0.2);
			}
			nav {
				display: block;
			}
		}
		nav {
			display: none;
			position: absolute;
			float: none;
			width: 200px;
			top: 43px;
			left: auto;
			right: 15px;
			height: auto;
			background-color: @theme-color;
			z-index: 11;
			ul {
				height: auto;
				background-color: rgba(0,0,0,0.2);
			}
			li {
				float: none;
				a {
					height: 40px;
					line-height: 40px;
					padding: 0 15px;
				}
			}
		}
	}

	#page-submenu-trigger {
		opacity: 1;
		pointer-events: auto;
		color: #FFF;
	}

	.ei-slider {
		height: 350px;
	}

	.ei-title {
		h3 {
			display: none;
		}
	}

	/* Responsive Sticky Header
	-----------------------------------------------------------------*/

	.sticky-responsive-menu {
		#header {
			min-height: 100px;
			-webkit-transition: min-height .4s ease;
			-o-transition: min-height .4s ease;
			transition: min-height .4s ease;
		}

		&.device-touch {
			#header,
			#header-wrap,
			#logo,
			#logo img,
			#top-search a,
			#primary-menu-trigger,
			#top-cart,
			#side-panel-trigger {
				-webkit-transition: none !important;
				-o-transition: none !important;
				transition: none !important;
			}
		}
	}

	#header.responsive-sticky-header { min-height: 60px; }

	.responsive-sticky-header{
		#header-wrap {
		    z-index: 199;
		    position: fixed;
		    top: 0;
		    left: 0;
		    width: 100%;
		    background-color: #FFF;
		    border-bottom: 1px solid #EEE;
		    -webkit-backface-visibility: hidden;
		}

		#logo,
		#logo img { height: 60px; }

		#primary-menu-trigger { top: 5px; }

		#primary-menu > ul,
		#primary-menu > .container > ul {
			max-height: 300px;
			overflow-y: scroll;
			margin-bottom: 20px;
		}

		#primary-menu > ul,
		#primary-menu > .container > ul { margin-bottom: 20px !important; }

		#top-search a,
		#top-cart,
		#side-panel-trigger { margin: 20px 0; }

		#top-search form { height: 60px !important; }
	}


	/* Portfolio
	-----------------------------------------------------------------*/

	#portfolio {
		&:not(.portfolio-1) {
			//margin: 0 -12px -12px 0 !important;
			.portfolio-item {
				width: 232px;
				margin: 0 12px 12px 0;
			}
		}

		&.portfolio-nomargin {
			.portfolio-item {
				width: 239px;
			}
		}

		&.portfolio-2 {
			.portfolio-item {
				width: 354px;
			}
			&.portfolio-nomargin {
				.portfolio-item {
					width: 359px;
				}
			}
		}

		&.portfolio-1 {
			.portfolio-item {
				.portfolio-image {
					float: none !important;
					margin: 0 !important;
					margin-bottom: 10px !important;
				}
			}
			.portfolio-desc {
				width: 100%;
			}
			&.portfolio-fullwidth {
				margin: 0 0 -60px 0;
				.portfolio-item {
					margin-bottom: 60px;
					padding-bottom: 0;
					border-bottom: 0;
					overflow: hidden;
					.portfolio-image {
						float: none;
						margin: 0 0 20px 0 !important;
						img {
							-webkit-transition: none;
							-o-transition: none;
							transition: none;
						}
					}
				}
				.portfolio-overlay {
					a {
						left: 50%;
					}
				}
				.alt {
					.portfolio-overlay {
						a {
							left: auto;
							right: 50%;
						}
					}
				}
			}
		}
	}

	#portfolio:not(.portfolio-1) .portfolio-item .portfolio-image,
	#portfolio:not(.portfolio-1) .portfolio-item .portfolio-image a,
	#portfolio:not(.portfolio-1) .portfolio-item .portfolio-image img {
		width: 232px;
		height: 175px;
	}

	#portfolio.portfolio-nomargin .portfolio-item .portfolio-image,
	#portfolio.portfolio-nomargin .portfolio-item .portfolio-image a,
	#portfolio.portfolio-nomargin .portfolio-item .portfolio-image img {
		width: 239px;
		height: 178px;
	}

	#portfolio.portfolio-2 .portfolio-item .portfolio-image,
	#portfolio.portfolio-2 .portfolio-item .portfolio-image a,
	#portfolio.portfolio-2 .portfolio-item .portfolio-image img {
		width: 354px;
		height: 264px;
	}

	#portfolio.portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image,
	#portfolio.portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image a,
	#portfolio.portfolio-2.portfolio-nomargin .portfolio-item .portfolio-image img {
		width: 359px;
		height: 269px;
	}

	#portfolio.portfolio-1 .portfolio-item .portfolio-image,
	#portfolio.portfolio-1 .portfolio-item .portfolio-image a,
	#portfolio.portfolio-1 .portfolio-item .portfolio-image img {
		width: 100%;
		height: auto;
	}

	#portfolio.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image,
	#portfolio.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image a,
	#portfolio.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image img {
		width: 100%;
		height: auto;
	}

	#portfolio.portfolio-1.portfolio-fullwidth .portfolio-item:hover .portfolio-image img,
	#portfolio.portfolio-1.portfolio-fullwidth .portfolio-item.alt:hover .portfolio-image img {
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		-ms-transform: translateX(0);
		-o-transform: translateX(0);
		transform: translateX(0);
	}

	#portfolio.portfolio-1.portfolio-fullwidth .portfolio-desc,
	#portfolio.portfolio-1.portfolio-fullwidth .alt .portfolio-desc {
		position: relative;
		width: 100%;
		height: auto;
		padding: 0;
		background-color: transparent;
		left: 0;
		right: 0;
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}

	.device-touch #portfolio.portfolio-parallax .portfolio-item .portfolio-desc,
	.device-touch #portfolio.portfolio-parallax .portfolio-overlay {
		opacity: 1;
	}

	#portfolio.portfolio-nomargin,
	#portfolio.portfolio-full {
		margin: 0 !important;
	}

	.shop-quick-view-ajax,
	.portfolio-ajax-modal {
		width: 90% !important;
	}

	.small-thumbs .entry-title h2,
	.ievent .entry-title h2 {
		font-size: 18px;
	}

	.entry-meta {
		li {
			font-size: 12px;
			margin: 0 10px 10px 0;
		}
	}

	/* Blog - Grid
	-----------------------------------------------------------------*/

	.post-grid {
		margin-right: -20px;
		.entry {
			width: 226px;
			margin-right: 20px;
		}
	}

	.post-grid .entry-image,
	.post-grid .entry-image img,
	.post-grid:not(.post-masonry) .entry-image iframe {
		height: 127px;
	}

	.post-grid.grid-2,
	.post-grid.grid-3 {
		margin-right: -30px;
	}

	.post-grid.grid-2 .entry,
	.post-grid.grid-3 .entry {
		width: 345px;
		margin-right: 30px;
	}

	.post-grid.grid-3 .entry-image,
	.post-grid.grid-3 .entry-image img,
	.post-grid.grid-3:not(.post-masonry) .entry-image iframe,
	.post-grid.grid-2 .entry-image,
	.post-grid.grid-2 .entry-image img,
	.post-grid.grid-2:not(.post-masonry) .entry-image iframe {
		height: 193px;
	}

	.post-masonry-full {
		.entry {
			width: 33.30% !important;
		}
		&.grid-3 {
			.entry {
				width: 50% !important;
			}
		}
	}

	.post-timeline {
		.entry {
			width: 290px !important;
			&.entry-date-section {
				width: 100% !important;
			}
		}
	}

	/* Shop
	-----------------------------------------------------------------*/

	.product {
		width: 226px;
		&:not(.product-2):not(.product-3) {
			.product-title {
				h3 {
					font-size: 18px;
				}
			}
		}
	}

	.product-image {
		height: 301px;
	}

	#shop.product-2,
	#shop.product-3 {
		margin: 0 -20px -20px 0;
	}

	#shop.product-2 .product,
	#shop.product-3 .product {
		width: 350px;
		margin: 0 20px 20px 0;
	}

	#shop.product-2 .product-image,
	#shop.product-3 .product-image {
		height: 467px;
	}

	.pricing {
		[class^=col-md-] {
			.pricing-box {
				&.best-price {
					margin-top: 0;
				}
			}
		}
		&.pricing-5 {
			.pricing-box {
				&.best-price {
					margin-top: 20px;
				}
			}
		}
	}

	.device-sm .pricing.pricing-5 .pricing-title h3,
	.device-sm .pricing.pricing-5 .best-price .pricing-title h3 {
		font-size: 14px;
	}

	.device-sm {
		.pricing {
			&.pricing-5 {
				.pricing-title {
					span {
						font-size: 12px;
					}
				}
				.pricing-price {
					font-size: 48px;
					span {
						font-size: 20px;
						&.price-tenure {
							font-size: 12px;
						}
					}
				}
				.pricing-box {
					&.best-price {
						.pricing-title {
							padding: 15px 0;
						}
					}
				}
			}
		}
	}

	.pricing-box.pricing-extended .pricing-desc,
	.pricing-box.pricing-extended .pricing-action-area {
		position: relative;
		float: none;
		width: auto;
		padding: 25px;
	}

	.pricing-box {
		&.pricing-extended {
			.pricing-action-area {
				height: auto;
				border-left: 0;
				border-top: 1px solid rgba(0,0,0,0.05);
				.border-radius-custom(0, 0, 3px, 3px)
			}
		}
	}

	.testimonials-grid {
		&.grid-3 {
			li {
				width: 50%;
			}
		}
	}

	.section {
		& > .testimonial {
			padding: 0 40px !important;
			max-width: none;
		}
	}

	.promo {
		&:not(.promo-full) {
			padding: 30px 20px !important;
		}
		&.promo-dark {
			h3 {
				line-height: 1.6;
			}
		}
		a {
			&.button {
				position: relative !important;
				top: 0 !important;
				margin: 20px 0 0 !important;
				left: 0 !important;
				right: 0;
			}
		}
	}

	#contact-form-overlay-mini {
		float: none;
		width: auto;
		max-width: 380px;
		margin: 0 auto;
	}

	.button {
		&.button-full {
			font-size: 22px;
			i {
				top: 3px !important;
			}
		}
	}

	.bnews-title {
		float: none;
		display: inline-block;
	}

	.bnews-slider {
		float: none;
		margin: 10px 0 0;
		width: 100%;
	}

	.wedding-head {
		font-size: 60px;
		.and {
			font-size: 144px;
		}
	}

	.wedding-head .first-name span,
		.wedding-head .last-name span {
		font-size: 40px;
	}

	.error404 {
		font-size: 180px;
	}

	.landing-form-overlay {
		position: absolute;
		width: 100%;
		max-width: 400px;
		right: auto;
		left: 50%;
		margin-left: -200px;
		bottom: 0;
	}

}

@media only screen and (min-width: 768px) and (max-width: 991px) {

	.swiper_wrapper:not(.full-screen):not(.canvas-slider-grid),
	 .swiper_wrapper:not(.full-screen):not(.canvas-slider-grid) .swiper-slide {
		height: 400px !important;
	}

	.sidebar-widgets-wrap {
		position: relative;
		margin: 0 -38px -40px 0;
		.widget {
			display: inline-block;
			vertical-align: top;
			float: none;
			width: 340px;
			margin: 0 37px 40px 0;
			padding-top: 0;
			border-top: 0;
		}
	}

	.landing-video {
		width: 352px;
		height: 199px;
		margin: 14px 0 0 60px;
	}

}

@media only screen and (max-width: 767px) {

	body {
		padding: 0 !important;
	}

	#wrapper {
		width: 100% !important;
	}

	.container-fullwidth {
		margin: 0 auto;
		padding: 0 15px !important;
	}

	h1 { font-size: 28px; }

	h2 { font-size: 24px; }

	h3 { font-size: 20px; }

	h4 { font-size: 16px; }

	#gotoTop,
	.one-page-arrow {
		display: none !important;
	}

	#top-bar {
		height: auto;
		.col_half {
			width: 100%;
			margin-bottom: 0 !important;
		}
	}

	.top-links,
		.top-links > ul > li {
		float: none !important;
	}

	.top-links {
		& > ul {
			text-align: center;
			& > li {
				display: inline-block;
				margin-right: -4px;
			}
		}
		ul {
			div {
				&.top-link-section {
					left: auto;
					right: 0;
					padding: 20px 15px;
				}
			}
		}
	}

	.top-links ul ul,
	.top-links ul div.top-link-section {
		text-align: left;
	}

	#gotoTop,
	.one-page-arrow {
		display: none !important;
	}
	#top-bar {
		height: auto;
		.col_half {
			width: 100%;
			margin-bottom: 0 !important;
		}
	}
	.top-links,
	.top-links > ul > li {
		float: none !important;
	}
	.top-links {
		& > ul {
			text-align: center;
			& > li {
				display: inline-block;
				margin-right: -4px;
			}
		}
		ul {
			div {
				&.top-link-section {
					left: auto;
					right: 0;
					padding: 20px 15px;
				}
			}
		}
	}

	.top-links ul ul,
	.top-links ul div.top-link-section {
		text-align: left;
	}

	#top-social,
	#slider:not(.swiper_wrapper) .slider-caption,
	.nivo-caption,
	.flex-caption,
	.slider-caption p,
	.slider-caption .button,
	.camera_caption,
	.ei-title,
	.slider-arrow-top-sm,
	.slider-arrow-bottom-sm {
		display: none !important;
	}

	.slider-caption {
		h2 {
			margin-bottom: 0 !important;
		}
	}

	.flex-control-nav {
		li {
			opacity: 1 !important;
		}
	}

	.fslider {
		&:not(.flex-thumb-grid) {
			.flex-control-nav {
				&.flex-control-thumbs {
					height: 60px;
				}
			}
		}
	}

	.fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs li,
	.fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs li img {
		display: block;
		width: 80px !important;
		height: 60px !important;
	}
	.prestations-liste,
	#content .content-wrap {
			padding:1em 0;	
	}
	.postcontent,
	.postcontent.bothsidebar,
    .sidebar,
    .col_full,
    .col_half,
    .col_one_third,
    .col_two_third,
    .col_three_fourth,
    .col_one_fourth,
    .col_one_fifth,
    .col_two_fifth,
    .col_three_fifth,
    .col_four_fifth,
    .col_one_sixth,
    .col_five_sixth {
		width: 100%;
		margin-right: 0;
		float: none;
		margin-bottom: 50px !important;
	}

	.sidebar {
		&.col_last {
			margin-bottom: 0 !important;
		}
	}

	form {
		.col_full,
		.col_half,
		.col_one_third,
		.col_two_third,
		.col_three_fourth,
		.col_one_fourth,
		.col_one_fifth,
		.col_two_fifth,
		.col_three_fifth,
		.col_four_fifth,
		.col_one_sixth,
		.col_five_sixth {
			margin-bottom: 25px !important;
		}
	}

	#page-title {
		padding: 20px 0 25px;
		text-align: center;
		h1 {
			line-height: 1.5;
			font-size: 24px;
		}
		span {
			display: none;
		}
		.breadcrumb {
			position: relative !important;
			top: 0 !important;
			left: 0 !important;
			right: 0 !important;
			margin: 15px 0 0 !important;
		}
	}

	#page-title #portfolio-navigation,
	#portfolio-ajax-title #portfolio-navigation {
		position: relative;
		top: 0;
		left: 0;
		margin: 15px auto 0;
	}
	

	#portfolio-ajax-title {
		text-align: center;
	}

	/* Portfolio - Filter
	-----------------------------------------------------------------*/

	#portfolio-filter {
		border: none;
		.border-radius(0);
		margin-bottom: 20px;
		li {
			width: 33.33%;
			margin-bottom: 20px;
			a {
				text-align: center;
				border-left: none;
				padding: 12px 18px !important;
				.border-radius(2px) !important;
				&:hover {
					color: @theme-color;
				}
			}
			&.activeFilter {
				a {
					color: #FFF;
					margin: 0;
				}
			}
		}
	}

	#portfolio-shuffle {
		display: none;
	}

	/* Blog
	-----------------------------------------------------------------*/

	.small-thumbs .entry-image,
	 .ievent .entry-image {
		float: none !important;
		width: 100% !important;
		margin: 0 0 20px !important;
	}

	.entry-title h2,
	.post-grid .entry-title h2 {
		font-size: 18px !important;
		font-weight: 600;
	}

	.entry-meta {
		li {
			font-size: 11px;
			margin: 0 10px 10px 0;
		}
	}

	#posts {
		.entry-meta {
			li {
				i {
					display: none;
				}
			}
		}
	}

	#posts.post-grid .entry-meta li i,
	#posts .entry-meta li:last-child i {
		display: inline-block;
	}

	.post-grid {
		margin-right: 0 !important;
	}
	.post-grid .entry,
	.post-masonry-full.grid-3 .entry {
		float: none !important;
		width: 100% !important;
		margin-right: 0 !important;
		border-right: 0 !important;
	}

	.post-timeline {
		.entry {
			&.entry-date-section {
				margin-left: 0;
			}
		}
	}

	.timeline-border,
	.post-timeline .entry-timeline {
		display: none !important;
	}

	.postcontent {
		.post-timeline {
			padding-left: 0;
		}
	}

	.post-masonry-full {
		.entry {
			padding: 40px !important;
		}
	}

	.post-grid .entry-image,
	.post-grid .entry-image img {
		height: auto !important;
	}

	.product-1 {
		.product {
			width: 100%;
		}
		.product-image {
			float: none !important;
			width: 100% !important;
			height: auto !important;
			margin: 0 0 20px !important;
		}
		.product-title {
			h3 {
				font-size: 19px;
			}
		}
		p {
			display: none;
		}
	}

	.button {
		&.button-desc {
			padding: 20px 17px;
			font-size: 15px;
			max-width: 100%;
			span { font-size: 11px; }
		}
	}

	
	h5 {
		margin: 10px 0 0 0;
	}


	.title-block {
		padding: 2px 0 3px 15px;
		border-left-width: 5px;
		h1 {
			& + span {
				font-size: 18px;
			}
		}
		h2 {
			& + span {
				font-size: 17px;
			}
		}
		h3 {
			& + span {
				font-size: 15px;
			}
		}
		h4 {
			& + span {
				font-size: 13px;
			}
		}
	}

	.title-block-right {
		padding: 2px 15px 3px 0;
		border-right-width: 5px;
	}

	.heading-block {
		h1 {
			font-size: 30px;
			& + span {
				font-size: 20px;
			}
		}
		h2 {
			font-size: 26px;
			& + span {
				font-size: 18px;
			}
		}
		h3 {
			font-size: 22px;
		}
		h4 {
			font-size: 19px;
		}
		& ~ p {
			&:not(.lead) {
				font-size: 15px;
			}
		}
	}

	.heading-block {
		h3 + span,
 		h4 + span {
			font-size: 17px;
		}
	}

	.emphasis-title {
		h1,
 		h2 {
			font-size: 44px ;
		}
	}

	.wedding-head {
		font-size: 48px;
		.first-name,
		.last-name,
		.and {
			display: block;
			margin: 0 0 15px;
			text-align: center;
		}
		.first-name span,
		.last-name span {
			font-size: 36px;
		}
		.and {
			font-size: 120px;
		}
	}

	.news-carousel .overlay {
		display: none;
	}

	/* Copyrights
	-----------------------------------------------------------------*/

	#copyrights {
		.col_half {
			text-align: center;
			&:last-child {
				display: none;
			}
		}
		.footer-logo {
			margin-left: auto;
			margin-right: auto;
		}
	}

}

@media only screen and (min-width: 480px) and (max-width: 767px) {

	//.container,
	#header.full-header .container,
	.container-fullwidth {
		width: 470px !important;
	}

	.container {
		&.vertical-middle {
			width: 100% !important;
		}
	}

	.full-screen {
		&:not(.force-full-screen) {
			.container {
				&.vertical-middle {
					width: 470px !important;
				}
			}
		}
	}

	#top-search {
		form {
			input {
				padding-right: 60px;
			}
		}
	}

	.swiper_wrapper:not(.force-full-screen),
		.swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
		height: 300px !important;
	}

	.swiper_wrapper {
		.slider-caption {
			h2 {
				font-size: 36px;
			}
		}
	}

	.ei-slider {
		height: 250px;
	}

	#header {
		&.transparent-header {
			&.floating-header {
				.container {
					width: 470px;
					padding: 0 15px;
				}
			}
		}
	}

	/* Portfolio - Items
	-----------------------------------------------------------------*/

	#portfolio {
		&:not(.portfolio-1) {
			margin: 0 -6px -6px 0;
			.portfolio-item {
				margin: 0 6px 6px 0;
				width: 217px;
				&:hover {
					.portfolio-overlay {
						.portfolio-desc {
							& ~ a {
								margin: 20px 0 0 !important;
								&.right-icon {
									margin-left: 5px !important;
									margin-right: 0 !important;
								}
							}
						}
					}
				}
			}
			.portfolio-desc {
				padding: 15px 5px 10px;
				h3 {
					font-size: 16px;
				}
				span {
					font-size: 14px;
				}
			}
		}
		&.portfolio-nomargin {
			&:not(.portfolio-1) {
				.portfolio-item {
					width: 219px;
				}
			}
		}
		&.portfolio-notitle {
			&:not(.portfolio-1) {
				.portfolio-desc {
					height: 78px;
					padding: 15px 5px;
					bottom: -79px;
				}
			}
		}
		&.portfolio-1 {
			&.portfolio-fullwidth {
				margin: 0 0 -60px 0;
			}
		}
	}

	#portfolio:not(.portfolio-1) .portfolio-item .portfolio-image,
	#portfolio:not(.portfolio-1) .portfolio-item .portfolio-image a,
	#portfolio:not(.portfolio-1) .portfolio-item .portfolio-image img {
		width: 217px;
		height: 162px;
	}

	#portfolio.portfolio-nomargin:not(.portfolio-1) .portfolio-item .portfolio-image,
	#portfolio.portfolio-nomargin:not(.portfolio-1) .portfolio-item .portfolio-image a,
	#portfolio.portfolio-nomargin:not(.portfolio-1) .portfolio-item .portfolio-image img {
		width: 219px;
		height: 164px;
	}

	#portfolio.portfolio-full.portfolio-notitle:not(.portfolio-1) .portfolio-desc,
	#portfolio.portfolio-nomargin.portfolio-notitle:not(.portfolio-1) .portfolio-desc {
		bottom: -78px;
		border-bottom: 0;
	}

	#portfolio.portfolio-notitle:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay a.left-icon,
	#portfolio.portfolio-notitle:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay a.right-icon,
	.portfolio-notitle .iportfolio:hover .portfolio-overlay a.left-icon,
	.portfolio-notitle .iportfolio:hover .portfolio-overlay a.right-icon {
		margin-top: -55px !important;
	}

	.portfolio-1 {
		.portfolio-item {
			.portfolio-image {
				float: none !important;
				margin: 0 !important;
				margin-bottom: 10px !important;
			}
		}
		.portfolio-desc {
			width: 100%;
		}
		&.portfolio-fullwidth {
			.portfolio-item {
				margin-bottom: 60px;
				padding-bottom: 0;
				border-bottom: 0;
				overflow: hidden;
				.portfolio-image {
					float: none;
					margin: 0 0 20px 0 !important;
					img {
						-webkit-transition: none;
						-o-transition: none;
						transition: none;
					}
				}
			}
			.portfolio-overlay {
				a {
					left: 50%;
				}
			}
			.alt {
				.portfolio-overlay {
					a {
						left: auto;
						right: 50%;
					}
				}
			}
		}
	}

	.portfolio-1 .portfolio-item .portfolio-image,
	.portfolio-1 .portfolio-item .portfolio-image a,
	.portfolio-1 .portfolio-item .portfolio-image img {
		width: 100%;
		height: auto;
	}

	.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image,
	.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image a,
	.portfolio-1.portfolio-fullwidth .portfolio-item .portfolio-image img {
		width: 100%;
		height: auto;
	}

	.portfolio-1.portfolio-fullwidth .portfolio-item:hover .portfolio-image img,
	.portfolio-1.portfolio-fullwidth .portfolio-item.alt:hover .portfolio-image img {
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		-ms-transform: translateX(0);
		-o-transform: translateX(0);
		transform: translateX(0);
	}

	.portfolio-1.portfolio-fullwidth .portfolio-desc,
	.portfolio-1.portfolio-fullwidth .alt .portfolio-desc {
		position: relative;
		width: 100%;
		height: auto;
		padding: 0;
		background-color: transparent;
		left: 0;
		right: 0;
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}

	#portfolio.portfolio-nomargin,
	#portfolio.portfolio-full {
		margin: 0 !important;
	}

	.post-grid {
		&:not(.post-masonry) {
			.entry-image {
				iframe {
					height: 246px;
				}
			}
		}
	}

	#shop {
		&:not(.product-1) {
			margin: 0 -12px -12px 0;
			.product {
				margin: 0 12px 12px 0;
				width: 214px;
			}
			.product-image {
				height: 285px;
			}
			.product-title {
				h3 {
					font-size: 17px;
				}
			}
		}
	}

	.clients-grid:not(.grid-3):not(.grid-2),
	.clients-grid.grid-4,
	.clients-grid.grid-6 {
		li {
			width: 33.33%;
		}

	}

	.button {
		&.button-full {
			font-size: 18px;
			i {
				top: 2px !important;
				margin-left: 3px !important;
			}
		}
	}
	.error404,
	.error404-wrap .error404 {
		font-size: 160px;
	}

	.landing-video {
		position: relative;
		top: 12px;
		width: 333px;
		height: 187px;
		margin: 19px 0 0 53px;
	}

}

@media only screen and (max-width: 479px) {
	
	#primary-menu .container #primary-menu-trigger {
		//left: -30px;
		position:relative;
	}
	.top-links > ul {
		text-align:left;
	}
	.top-links ul li {
		border:none;
	}
	.container {
		width:100%;
	}
	#header.full-header .container,
		.container-fullwidth {
		width: 310px !important;
	}

	.container {
		&.vertical-middle {
			width: 100% !important;
		}
	}

	.full-screen {
		&:not(.force-full-screen) {
			.container {
				&.vertical-middle {
					width: 310px !important;
				}
			}
		}
	}

	#top-cart {
		display: none;
	}

	#top-search {
		a {
			right: -5px;
		}
		form {
			input {
				padding-right: 30px;
			}
		}
	}

	#side-panel-trigger { right: 43px; }

	.swiper_wrapper:not(.force-full-screen),
	.swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide,
	.ei-slider {
		height: 180px !important;
	}

	.swiper_wrapper {
		.slider-caption {
			h2 {
				font-size: 28px;
			}
		}
	}

	#header {
		&.transparent-header {
			&.floating-header {
				.container {
					width: 310px;
					padding: 0 15px;
				}
			}
		}
	}

	#portfolio-filter li {
		width: 50%;
	}
	#portfolio:not(.portfolio-1) {
		margin: 0 ;
		.portfolio-item {
			width: 280px;
			.portfolio-image,
			.portfolio-image a,
			.portfolio-image img {
				width: 280px;
				height: 211px;
			}
		}
	}
	
	#portfolio:not(.portfolio-1) .portfolio-item, #portfolio:not(.portfolio-1) .portfolio-item .portfolio-image, #portfolio:not(.portfolio-1) .portfolio-item .portfolio-image a, #portfolio:not(.portfolio-1) .portfolio-item .portfolio-image img {
		width:100%; height:auto;
	}

	.post-grid {
		&:not(.post-masonry) {
			.entry-image {
				iframe {
					height: 157px;
				}
			}
		}
	}

	#shop {
		&:not(.product-1) {
			margin: 0;
			.product {
				margin: 0 0 20px 0;
				width: 100%;
			}
			.product-image {
				height: 373px;
			}
			.product-title {
				h3 {
					font-size: 19px;
				}
			}
		}
	}

	.fslider:not(.flex-thumb-grid) .flex-control-nav.flex-control-thumbs {
		height: 45px;
		li,
		li img {
			display: block;
			width: 60px ;
			height: 45px ;
		}
	}

	.si-share {
		span {
			display: none;
		}
	}

	.parallax .entry-overlay-meta,
	.parallax .entry-overlay {
		left: 50% !important;
		right: 0 !important;
		margin: 0 0 0 -140px !important;
		width: 280px !important;
	}

	.parallax {
		.entry-overlay {
			width: 288px !important;
		}
		.countdown-section {
			width: 64px;
			height: 64px;
			margin: 0 8px 0 0;
			font-size: 11px;
			padding: 0;
			padding-top: 12px;
		}
		.countdown-amount {
			font-size: 20px;
		}
	}

	.countdown-large {
		.countdown-section {
			display: block;
			width: auto;
			margin: 30px 0 0;
			border: 0;
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.testi-image,
	.testi-image a,
	.testi-image img,
	.testi-image i {
		width: 36px;
		height: 36px;
	}

	.testi-image {
		i {
			line-height: 36px;
			font-size: 20px;
		}
	}

	.clients-grid:not(.grid-3):not(.grid-2) li,
	.clients-grid.grid-4 li,
	.clients-grid.grid-6 li {
		width: 50%;
	}

	.button {
		&.button-full {
			font-size: 16px;
			i {
				top: 2px !important;
				margin-left: 3px !important;
			}
		}
	}

	.emphasis-title h1,
		.emphasis-title h2 {
		font-size: 40px !important;
	}

	.error404,
	.error404-wrap .error404 {
		font-size: 120px;
	}

	.landing-video {
		position: relative;
		top: 9px;
		width: 212px;
		height: 119px;
		margin: 19px 0 0 34px;
	}

	.landing-form-overlay {
		margin-left: -155px;
	}

	.button {
		&.button-desc {
			padding: 20px 17px;
			font-size: 15px;
			max-width: 100%;
			span { font-size: 11px; }
		}
	}

}

/* ----------------------------------------------------------------
    Retina CSS
-----------------------------------------------------------------*/


@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {

	#logo {
		a {
			&.standard-logo {
				display: none;
			}
			&.retina-logo {
				display: block;
			}
		}
	}

	.owl-carousel.owl-loading,
	.preloader,
	.preloader2,
	.form-process {
		background-image: url('../images/preloader@2x.gif');
		background-size: 24px 24px;
	}

	.dark{
		.owl-carousel.owl-loading,
		.preloader,
		.preloader2,
		.form-process {
			background-image: url('../images/preloader-dark@2x.gif');
		}

	}

	.widget_recent_comments {
		li {
			background-image: url("../images/icons/widget-comment@2x.png");
			background-size: 15px 26px;
		}
	}

	.widget_nav_menu,
	.widget_links,
	.widget_meta,
	.widget_archive,
	.widget_recent_entries,
	.widget_categories,
	.widget_pages,
	.widget_rss,
	.widget_recent_comments{
		 li a {
			background-image: url("../images/icons/widget-link@2x.png");
			background-size: 4px 20px;
		}
	}

	.dark {
		.widget_recent_comments {
			li {
				background-image: url("../images/icons/widget-comment-dark@2x.png");
			}
		}
	}

	.dark {
		.widget_nav_menu,
		.widget_links,
		.widget_meta,
		.widget_archive,
		.widget_recent_entries,
		.widget_categories,
		.widget_pages,
		.widget_rss,
		.widget_recent_comments {
			 li a {
			 	background-image: url("../images/icons/widget-link-dark@2x.png");
			}
		}

	}

	@media
	only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991px),
	only screen and (   min--moz-device-pixel-ratio: 2) and (max-width: 991px),
	only screen and (     -o-min-device-pixel-ratio: 2/1) and (max-width: 991px),
	only screen and (        min-device-pixel-ratio: 2) and (max-width: 991px),
	only screen and (                min-resolution: 192dpi) and (max-width: 991px),
	only screen and (                min-resolution: 2dppx) and (max-width: 991px) {
		#logo {
			a {
				&.standard-logo {
					display: none;
				}
				&.retina-logo {
					display: inline-block;
				}
			}
		}

	}

}
