/*-----------------------------------------------------------------------------------
    mixins.less
-----------------------------------------------------------------------------------*/



.size(@width; @height) {
    width: @width;
    height: @height;
}

.square(@size) {
    .size(@size; @size);
}


/* Portfolio Item Size Builder
-----------------------------------------------------------------*/

.portfolio-builder(@container, @columns, @margin) {
    @width: floor( ( @container - ( @margin * ( @columns - 1 ) ) ) / @columns );
    @height: floor( ( @width * 3 ) / 4 );
}

.portfolio-item-width(@container; @columns; @margin) {
    .portfolio-builder(@container, @columns, @margin);
    width: @width;
}

.portfolio-item-size(@container; @columns; @margin) {
    .portfolio-builder(@container, @columns, @margin);
    .size(@width; @height);
}


/* Shop Item Size Builder
-----------------------------------------------------------------*/

.shop-builder(@container, @columns, @margin) {
    @width: floor( ( @container - ( @margin * ( @columns - 1 ) ) ) / @columns );
    @height: floor( ( @width * 4 ) / 3 );
}

.shop-item-width(@container; @columns; @margin) {
    .shop-builder(@container, @columns, @margin);
    width: @width;
}

.shop-item-height(@container; @columns; @margin) {
    .shop-builder(@container, @columns, @margin);
    height: @height;
}


/* Blog Grid Item Size Builder
-----------------------------------------------------------------*/

.blog-grid-builder(@container, @columns, @margin) {
    @width: floor( ( @container - ( @margin * ( @columns - 1 ) ) ) / @columns );
    @height: floor( ( @width * 37 ) / 66 );
}

.blog-grid-item-width(@container; @columns; @margin) {
    .blog-grid-builder(@container, @columns, @margin);
    width: @width;
}

.blog-grid-item-height(@container; @columns; @margin) {
    .blog-grid-builder(@container, @columns, @margin);
    height: @height;
}


/* Other Mixins
-----------------------------------------------------------------*/

.border-radius(@radius: 3px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.border-radius-custom(@topleft: 3px, @topright: 3px, @bottomright: 3px, @bottomleft: 3px) {
    -webkit-border-radius: @topleft @topright @bottomright @bottomleft;
    -moz-border-radius: @topleft @topright @bottomright @bottomleft;
    border-radius: @topleft @topright @bottomright @bottomleft;
}

.box-shadow(@shadow) {
    -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
    -moz-box-shadow: @shadow;
    box-shadow: @shadow;
}

.transition(@transition) {
    -webkit-transition: @transition;
    -o-transition: @transition;
    transition: @transition;
}

.transition-transform(@transition) {
    -webkit-transition: -webkit-transform @transition;
    -moz-transition: -moz-transform @transition;
    -o-transition: -o-transform @transition;
    transition: transform @transition;
}

.degradeOrange {
    background: #ec7b00;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjU3JSIgc3RvcC1jb2xvcj0iI2VjN2IwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkMzQ5MDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(-45deg,  #ec7b00 57%, #d34900 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(57%,#ec7b00), color-stop(100%,#d34900));
    background: -webkit-linear-gradient(-45deg,  #ec7b00 57%,#d34900 100%);
    background: -o-linear-gradient(-45deg,  #ec7b00 57%,#d34900 100%);
    background: -ms-linear-gradient(-45deg,  #ec7b00 57%,#d34900 100%);
    background: linear-gradient(135deg,  #ec7b00 57%,#d34900 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ec7b00', endColorstr='#d34900',GradientType=1 );
}

.borderPlus(@rayon:24px) {
    border-top-left-radius: @rayon;
    border-bottom-right-radius: @rayon;
}
